"use client";

import * as Sentry from "@sentry/nextjs";
import { type Session } from "next-auth";
import { getCsrfToken, useSession } from "next-auth/react";
import { useEffect } from "react";
import { Spinner } from "shared-ui";

import { signIn } from "@/authentication/authService";
import { useVirtualVerificationStore } from "@/store/virtualVerificationStore";

type TSigninPage = {
  session?: Session | null;
  searchParams: { [key: string]: string | undefined };
};

const SigninPage = ({ searchParams }: TSigninPage) => {
  const session = useSession();
  const { clearStore: clearVirtualVerificationStore } =
    useVirtualVerificationStore();
  const callbackUrl = searchParams.callbackUrl ?? "";

  const fetchCsrfToken = async () => {
    const result = await getCsrfToken();
    if (!result) {
      throw new Error("Can not sign in without a CSRF token");
    }
  };

  useEffect(() => {
    if (session?.status !== "loading") {
      fetchCsrfToken();
      if (session?.data?.user?.id) {
        Sentry.setUser({
          id: session?.data?.user?.id,
        });
      }
      clearVirtualVerificationStore(); // reset virtual verification store
      signIn("auth0", {
        callbackUrl,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session?.status]);

  return (
    <div className="grid h-full w-full items-center justify-center">
      <div>
        <Spinner size="large" color="primary" className="!m-auto block" />
        <div className="mt-5 text-primary600">Please wait...</div>
      </div>
    </div>
  );
};

export default SigninPage;
