import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

type TVirtualVerificationData = {
  id: string;
  url: string;
};

type TUseVirtualVerificationStore = {
  data?: TVirtualVerificationData;
  setVirtualVerificationData: (data: TVirtualVerificationData) => void;
  clearStore: () => void;
};

const defaultState = {};

export const useVirtualVerificationStore =
  create<TUseVirtualVerificationStore>()(
    devtools(
      persist(
        (set) => ({
          ...defaultState,
          setVirtualVerificationData: (data) =>
            set(() => ({
              data,
            })),
          clearStore: () => {
            useVirtualVerificationStore.persist.clearStorage();
            set(defaultState);
          },
        }),
        {
          name: "virtual-verification-data-storage",
          storage: createJSONStorage(() => localStorage),
        }
      )
    )
  );
